import React, { useState, useEffect } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';


const HeaderView = (props:any) => {
    const [user, setUser] = useState({first_name: '', role: {name: ''}});

    useEffect( () => {
        let userString:string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
        const userObj = JSON.parse(userString ? userString : '');
        setUser(userObj);
    }, []);

    const onLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        props.history.push('/login');
    }

    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }
   
    return (
        <div className="header">
            <div className="header__left">
                <div className="header__logo" style={props.siderCollapsed ? { width: 80, transition: "width 0.2s" } : { width: 200, transition: "width 0.2s" }}>
                    <div style={{ margin: '0 auto' }}>
                        <img src="/logo.png" alt="logo" width="50" height="40"></img>
                    </div>
                </div>
                <div className="header__toggle">
                    <div onClick={toggleSidebar} className="toggle-icon">
                        {props.siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined/>}    
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <b>Matiao Marketing, Inc.</b>
                    </div>
                </div>
            </div>
            <div className="header__right">
                <div className="profile-container">
                    <div><UserOutlined/></div>
                    
                    <div className="profile-details">
                        <label>{user.first_name}</label>
                    </div>
                </div>
                <div className="action-icon primary">
                <Tooltip placement="bottomRight" title={'Logout'}>
                    <ExportOutlined onClick={onLogout}/>
                </Tooltip>
                    
                </div>
            </div>
        </div>
    );
}

export default withRouter(HeaderView)