
/**
 * App configuration
 */
class APPConfig
{
    /**
     * api url endpoint
     */
    static MODULES:any = {
        dashboard: 'dashboard',
        users: 'users',
        categories: 'categories',
        areas: 'areas',
        locations: 'locations',
        uoms: 'uoms',
        products: 'products',
        branches: 'branches',
        inventories: 'inventories',
        inventory_counts: 'inventory-counts',
        inventory_reports: 'inventory-reports',
        suppliers: 'suppliers',
        purchase_orders: 'purchase-orders',
        purchase_order_line_details: 'purchase-order-line-details',
        roles: 'roles',
        customers: 'customers',
        customer_prices: 'customer-prices',
        sales_orders: 'sales-orders',
        sales_order_line_details: 'sales-order-line-details',
        customer_payments: 'customer-payments',
        daily_sales_report: 'daily-sales-report',
        daily_collections_report: 'daily-collections-report',
        aging_report: 'aging-report',
        customer_ledger: 'customer-ledger',
    };
    static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://index-api.ark.ph/api';
}

export default APPConfig;