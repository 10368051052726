import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import LayoutView from "./modules/layout/LayoutView";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}

//include modules
const Login:any = lazyComponentLoader(() => import('./modules/auth/login/Login'));
const Forgot:any = lazyComponentLoader(() => import('./modules/auth/forgot-password/ForgotPassword'));
const Reset:any = lazyComponentLoader(() => import('./modules/auth/forgot-password/ResetPassword'));
const UserManagement:any = lazyComponentLoader(() => import('./modules/user-management/UserManagementView'));
const BranchManagement:any = lazyComponentLoader(() => import('./modules/branch-management/BranchManagementView'));
const CategoryManagement:any = lazyComponentLoader(() => import('./modules/category-management/CategoryManagementView'));
const AreaManagement:any = lazyComponentLoader(() => import('./modules/area-management/AreaManagementView'));
const LocationManagement:any = lazyComponentLoader(() => import('./modules/location-management/LocationManagementView'));
const UOMManagement:any = lazyComponentLoader(() => import('./modules/uom-management/UOMManagementView'));
const ProductList:any = lazyComponentLoader(() => import('./modules/product-management/ProductListView'));
const ProductDetail:any = lazyComponentLoader(() => import('./modules/product-management/ProductDetailView'));
const InventoryCount:any = lazyComponentLoader(() => import('./modules/inventory-count/InventoryCountSessionListView'));
const InventoryCountDetails:any = lazyComponentLoader(() => import('./modules/inventory-count/InventoryCountDetailListView'));
const Dashboard:any = lazyComponentLoader(() => import('./modules/dashboard/DashboardView'));
const SupplierManagement:any = lazyComponentLoader(() => import('./modules/supplier-management/SupplierManagementView'));
const PurchaseOrderList:any = lazyComponentLoader(() => import('./modules/purchase-order/PurchaseOrderListView'));
const PurchaseOrder:any = lazyComponentLoader(() => import('./modules/purchase-order/PurchaseOrderView'));
const CustomerList:any = lazyComponentLoader(() => import('./modules/customer-management/CustomerListView'));
const CustomerDetailView:any = lazyComponentLoader(() => import('./modules/customer-management/CustomerView'));
const CustomerPrices:any = lazyComponentLoader(() => import('./modules/customer-pricing-management/CustomerPricingManagementView'));
const SalesOrderList:any = lazyComponentLoader(() => import('./modules/sales-order/SalesOrderListView'));
const SalesOrder:any = lazyComponentLoader(() => import('./modules/sales-order/SalesOrderView'));
const DailySalesReportList:any = lazyComponentLoader(() => import('./modules/daily-sales-report/DailySalesReportListView'));
const DailyCollectionsReportList:any = lazyComponentLoader(() => import('./modules/daily-collections-report/DailyCollectionsReportListView'));
const AgingReportList:any = lazyComponentLoader(() => import('./modules/aging-report/AgingReportListView'));
const CustomerLedgerView:any = lazyComponentLoader(() => import('./modules/customer-ledger/CustomerLedgerView'));
const RoleManagement:any = lazyComponentLoader(() => import('./modules/role-management/RoleManagementView'));

class App extends Component<any, IAppState> {

  constructor(props:any) {
    super(props);


    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content = null;
    if(isArray(errors))
    {
     content = (
      < div>{
            errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})
            }
      </div>    
      );
    }else {
     content = ( <div style={{whiteSpace: 'pre-line'}}>{errors.message}</div> );
    }
    
    DialogUtils.error(content);
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
                {/* render={props => (<Login {...props} />)} */}
                <Route exact path='/login' component={Login}/>
                <Route exact path='/forgot-password' component={Forgot}/>
                <Route exact path='/reset-password' component={Reset}/>
                <LayoutView>
                  <ProtectedRoute exact path='/' component={Dashboard} />
                  <ProtectedRoute exact path='/dashboard' component={Dashboard} />
                  <ProtectedRoute exact path='/users' component={UserManagement} />
                  <ProtectedRoute exact path='/branches' component={BranchManagement} />
                  <ProtectedRoute exact path='/categories' component={CategoryManagement} />
                  <ProtectedRoute exact path='/areas' component={AreaManagement} />
                  <ProtectedRoute exact path='/locations' component={LocationManagement} />
                  <ProtectedRoute exact path='/uoms' component={UOMManagement} />
                  <ProtectedRoute exact path='/products' component={ProductList} />
                  <ProtectedRoute exact path='/products/:id' component={ProductDetail} />
                  <ProtectedRoute exact path='/inventory-counts' component={InventoryCount} />
                  <ProtectedRoute exact path='/inventory-counts/:id' component={InventoryCountDetails} />
                  <ProtectedRoute exact path='/suppliers' component={SupplierManagement} />
                  <ProtectedRoute exact path='/purchase-orders' component={PurchaseOrderList} />
                  <ProtectedRoute exact path='/purchase-orders/:id' component={PurchaseOrder} />
                  <ProtectedRoute exact path='/customers' component={CustomerList} />
                  <ProtectedRoute exact path='/customers/:id' component={CustomerDetailView} />
                  <ProtectedRoute exact path='/customer-prices' component={CustomerPrices} />
                  <ProtectedRoute exact path='/sales-orders' component={SalesOrderList} />
                  <ProtectedRoute exact path='/sales-orders/:id' component={SalesOrder} />
                  <ProtectedRoute exact path='/daily-sales-report' component={DailySalesReportList} />
                  <ProtectedRoute exact path='/daily-collections-report' component={DailyCollectionsReportList} />
                  <ProtectedRoute exact path='/aging-report' component={AgingReportList} />
                  <ProtectedRoute exact path='/customer-ledger' component={CustomerLedgerView} />
                  <ProtectedRoute exact path='/roles' component={RoleManagement} />
               </LayoutView>  
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;