import React, { Component } from 'react';
import { Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import APPConfig from '../../config';
import { AppstoreOutlined,
    BarChartOutlined,
    PieChartOutlined,
    ProfileOutlined,
    FileOutlined } from '@ant-design/icons';
 const { SubMenu } = Menu;
    

class SideMenuView extends Component<any> {   
    currentPath = this.props.location.pathname;
    userString = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
    allMenuItems = [];
    salesOrderAndPurchaseOrder = [];
    reports = [];

    constructor(props:any){
        super(props);

        this.currentPath = this.props.location.pathname;
    }
   
   toggleSidebar = () => {
        this.props.collapseToggle(!this.props.siderCollapsed)
   }

   onToggleMenus = (action:string) => {
        this.currentPath = action;
        this.props.history.push(action);
   }

   showMenuItems = () => {
        const userObj = JSON.parse(this.userString ? this.userString : '');
         const modules = APPConfig.MODULES;
         let menuItems = [] as any;
         let salesOrderAndPurchaseOrderItems = [] as any;
         let reportItems = [] as any;

         let adminMenuItems = [
                                "roles",
                                "branches",
                                "users",
                                "areas",
                                "locations",
                                "categories",
                                "uoms",
                                "products",
                                "inventory-counts",
                                "customers",
                                "customer-prices",
                                "suppliers",
                                "sales-orders",
                                "purchase-orders",
                                "daily-sales-report",
                                "daily-collections-report",
                                "aging-report",
                                "customer-ledger",
                              ] as any;

         let adminSalesOrderAndPurchaseOrderItems = [
                                                      "sales-orders",
                                                      "purchase-orders",
                                                    ] as any;

         let adminReportItems = [
                                  "daily-sales-report",
                                  "daily-collections-report",
                                  "aging-report",
                                  "customer-ledger",
                                ] as any;

         // check if role is set and if role has role_has_permissions
         if (userObj.role) {
           if (userObj.role.role_has_permissions && userObj.role.role_has_permissions.length > 0) {
              const userRolePermission = userObj.role.role_has_permissions;

              // loop through userRolePermission and check if it exist in the modules object
              userRolePermission.map((rolePermission:any, index:number) => {
                  if (rolePermission.permissions.length > 0) {
                      switch (rolePermission.module) {
                          // USERS
                          case modules.users:
                            menuItems[index] = modules.users;
                            break;
                          // BRANCHES
                          case modules.branches:
                            menuItems[index] = modules.branches;
                            break;
                          // CATEGORIES
                          case modules.categories:
                            menuItems[index] = modules.categories;
                            break;
                          // AREAS
                          case modules.areas:
                            menuItems[index] = modules.areas;
                            break;
                          // LOCATIONS
                          case modules.locations:
                            menuItems[index] = modules.locations;
                            break;
                          // UOMS
                          case modules.uoms:
                            menuItems[index] = modules.uoms;
                            break;
                          // PRODUCTS
                          case modules.products:
                            menuItems[index] = modules.products;
                            break;
                          // INVENTORY_COUNTS
                          case modules.inventory_counts:
                            menuItems[index] = modules.inventory_counts;
                            break;
                          // SUPPLIERS
                          case modules.suppliers:
                            menuItems[index] = modules.suppliers;
                            break;
                          // CUSTOMERS
                          case modules.customers:
                            menuItems[index] = modules.customers;
                            break;
                          // CUSTOMER PRICES
                          case modules.customer_prices:
                              menuItems[index] = modules.customer_prices;
                              break;  
                          // ROLES
                          case modules.roles:
                              menuItems[index] = modules.roles;
                              break;
                          // SALES ORDERS
                          case modules.sales_orders:
                              salesOrderAndPurchaseOrderItems[index] = modules.sales_orders;
                              break;
                          // PURCHASE_ORDERS
                          case modules.purchase_orders:
                              salesOrderAndPurchaseOrderItems[index] = modules.purchase_orders;
                              break;
                          // DAILY_SALES_REPORT
                          case modules.daily_sales_report:
                              reportItems[index] = modules.daily_sales_report;
                              break;
                          // DAILY_COLLECTIONS_REPORT
                          case modules.daily_collections_report:
                              reportItems[index] = modules.daily_collections_report;
                              break;
                          // AGING_REPORT
                          case modules.aging_report:
                              reportItems[index] = modules.aging_report;
                              break;
                          // CUSTOMER_LEDGER
                          case modules.customer_ledger:
                              reportItems[index] = modules.customer_ledger;
                              break;
                          
                          default:
                              break;
                      }
                  }
              });

              this.allMenuItems = menuItems;
              this.salesOrderAndPurchaseOrder = salesOrderAndPurchaseOrderItems;
              this.reports = reportItems;
           }
         }

         if (userObj.role) {
           if (userObj.role.name == 'Admin' || userObj.role.name == 'admin') {
             this.allMenuItems = adminMenuItems;
             this.salesOrderAndPurchaseOrder = adminSalesOrderAndPurchaseOrderItems;
             this.reports = adminReportItems;
           }
         }
    }

    menuItem(menu:any) {
        const modules = APPConfig.MODULES;

        switch (menu) {
          // USERS
          case modules.users:
            return <Menu.Item key="/users" onClick={this.onToggleMenus.bind(this, '/users')}>
                        <span>Users</span>
                    </Menu.Item>
            break;
          // BRANCHES
          case modules.branches:
            return <Menu.Item key="/branches" onClick={this.onToggleMenus.bind(this, '/branches')}>
                        <span>Branches</span>
                    </Menu.Item>
            break;
          // AREAS
          case modules.areas:
            return <Menu.Item key="/areas" onClick={this.onToggleMenus.bind(this, '/areas')}>
                        <span>Areas</span>
                    </Menu.Item>
            break;
          // LOCATIONS
          case modules.locations:
            return <Menu.Item key="/locations" onClick={this.onToggleMenus.bind(this, '/locations')}>
                        <span>Locations</span>
                    </Menu.Item>
            break;
          // CATEGORIES
          case modules.categories:
            return <Menu.Item key="/categories" onClick={this.onToggleMenus.bind(this, '/categories')}>
                        <span>Product Categories</span>
                    </Menu.Item>
            break;
          // UOMS
          case modules.uoms:
            return <Menu.Item key="/uoms" onClick={this.onToggleMenus.bind(this, '/uoms')}>
                        <span>Unit of Measures</span>
                    </Menu.Item>
            break;
          // PRODUCTS
          case modules.products:
            return <Menu.Item key="/products" onClick={this.onToggleMenus.bind(this, '/products')}>
                        <span>Products</span>
                    </Menu.Item>
            break;
          // INVENTORY_COUNTS
          case modules.inventory_counts:
            return <Menu.Item key="/inventory_counts" onClick={this.onToggleMenus.bind(this, '/inventory-counts')}>
                        <span>Inventory Count</span>
                    </Menu.Item>
            break;
          // CUSTOMERS
          case modules.customers:
            return <Menu.Item key="/customers" onClick={this.onToggleMenus.bind(this, '/customers')}>
                        <span>Customers</span>
                    </Menu.Item>
            break;
          case modules.customer_prices:
            return <Menu.Item key="/customer-prices" onClick={this.onToggleMenus.bind(this, '/customer-prices')}>
                        <span>Customer Pricing</span>
                   </Menu.Item>
            break;
          // SUPPLIERS
          case modules.suppliers:
            return <Menu.Item key="/suppliers" onClick={this.onToggleMenus.bind(this, '/suppliers')}>
                        <span>Suppliers</span>
                    </Menu.Item>
            break;
          case modules.roles:  
            return <Menu.Item key="/roles" onClick={this.onToggleMenus.bind(this, '/roles')}>
                       <span>Roles</span>
                   </Menu.Item>;
            break;
        }
    }

    salesOrderAndPurchaseOrderItem(menu:any) {
        const modules = APPConfig.MODULES;

        switch (menu) {
          // SALES ORDERS
          case modules.sales_orders:
            return <Menu.Item key="/sales-orders" onClick={this.onToggleMenus.bind(this, '/sales-orders')}>
                    <FileOutlined />
                    <span>Sales Orders</span>
                  </Menu.Item>
            break;
          // PURCHASE ORDERS
          case modules.purchase_orders:
            return <Menu.Item key="/purchase-orders" onClick={this.onToggleMenus.bind(this, '/purchase-orders')}>
                    <ProfileOutlined />
                    <span>Purchase Orders</span>
                  </Menu.Item>
            break;
        }
    }

    reportsItem(menu:any) {
        const modules = APPConfig.MODULES;

        switch (menu) {
          // DAILY_SALES_REPORT
          case modules.daily_sales_report:
            return <Menu.Item key="/daily-sales-report" onClick={this.onToggleMenus.bind(this, '/daily-sales-report')}>
                      <span>Daily Sales Reports</span>
                  </Menu.Item>
            break;
          // DAILY_COLLECTIONS_REPORT
          case modules.daily_collections_report:
            return <Menu.Item key="/daily-collections-report" onClick={this.onToggleMenus.bind(this, '/daily-collections-report')}>
                      <span>Daily Collections Reports</span>
                  </Menu.Item>
            break;
          // AGING_REPORT
          case modules.aging_report:
            return <Menu.Item key="/aging-report" onClick={this.onToggleMenus.bind(this, '/aging-report')}>
                      <span>Aging Reports</span>
                  </Menu.Item>
            break;
          // CUSTOMER_LEDGER
          case modules.customer_ledger:
            return <Menu.Item key="/customer-ledger" onClick={this.onToggleMenus.bind(this, '/customer-ledger')}>
                      <span>Customers Ledgers</span>
                  </Menu.Item>
            break;
        }
    }

    render() {
        this.showMenuItems();
        return (
            <div className="sidebar">
                {/* <div className="sideToggle">
                    <div onClick={this.toggleSidebar} className="toggle-icon">
                        {this.props.siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined/>}    
                    </div>
                </div> */}
                <Menu mode="inline" defaultSelectedKeys={[this.currentPath]}>
                    <Menu.Item key="/dashboard" onClick={this.onToggleMenus.bind(this, '/dashboard')}>
                        <AppstoreOutlined />
                        <span>Dashboard</span>
                    </Menu.Item>
                    <SubMenu 
                        key="/maintenance" 
                        title={
                            <>
                                <BarChartOutlined />
                                <span>Maintenance</span>
                            </>
                        }
                        style={{ margin: 0 }}
                    >
                        {this.allMenuItems ? this.allMenuItems.map((menu:any) => this.menuItem(menu)) : ''}
                        
                    </SubMenu>
                    {this.salesOrderAndPurchaseOrder ? this.salesOrderAndPurchaseOrder.map((menu:any) => this.salesOrderAndPurchaseOrderItem(menu)) : ''}
                    <SubMenu 
                        key="/reports" 
                        title={
                            <>
                                <PieChartOutlined />
                                <span>Reports</span>
                            </>
                        }
                        style={{ margin: 0 }}
                    >
                        {this.reports ? this.reports.map((menu:any) => this.reportsItem(menu)) : ''}
                    </SubMenu>
                </Menu>
            </div>
        );
    }
}

export default withRouter(SideMenuView)